import React, {useCallback} from 'react';
import {FSMStore, productSheetStore} from 'webgl/stores';
import {sizeClassStore} from 'stores';
import HtmlSlideBox from 'components/Shared/HtmlSlideBox';

import './HtmlTitleDisplay.scss';
import {useGetCurrentStateIndex} from 'webgl/hooks';

const HtmlTitleDisplay: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const productSheetSubStatesArray = productSheetStore(state => state.productSheetSubStatesArray);
  const {currentIndex} = useGetCurrentStateIndex();
  const setFSMState = FSMStore(state => state.setFSMState);

  const goNextState = useCallback(() => {
    const nextState = productSheetSubStatesArray[currentIndex + 1];
    if (nextState !== undefined) setFSMState(nextState.value);
  }, [currentIndex, productSheetSubStatesArray, setFSMState]);

  return (
    <>
      <HtmlSlideBox visibleFrom={0} visibleTo={1} className={`html-title-display first ${sizeClass} ${currentIndex < 1 ? ' default-state':''}`}>
        <span className={'first-line'}>{currentIndex < 1 ? "VOTRE MENUISERIE SUR MESURE" : "VOTRE MENUISERIE SUR MESURE EN 3D"}</span>
      </HtmlSlideBox>

      <HtmlSlideBox visibleFrom={0} visibleTo={1} className={`html-title-display second ${sizeClass}`}>
        <span className={'deploy-btn'} onClick={goNextState}>Déployer</span>
      </HtmlSlideBox>
    </>
  );
};

export default HtmlTitleDisplay;