import React, {useMemo, useRef} from 'react';
import {Environment} from '@react-three/drei';
import {DirectionalLight} from 'three';
import {SizeClass, sizeClassStore} from 'stores/sizeClassStore';

const Lighting: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);

  const light = useMemo(() => {
    const light = new DirectionalLight(0xffffff);
    // const light = new DirectionalLight(0xff0000);
    light.intensity = 4;
    // light.castShadow = true;
    // light.shadow.mapSize = new Vector2(1024, 1024);
    // light.shadow.bias = -0.00025;
    return light;
  }, []);

  const lightRef = useRef();
  // useHelper(lightRef, DirectionalLightHelper);

  return (
    <>
      {/*<Environment files={'/assets/hdri/empty_warehouse_01_1k_edited.hdr'} background={false}/>*/}
      {/*<Environment files={'./assets/hdri/DH-334LP.hdr'} background={false}/>*/}
      <Environment preset="warehouse" />

      {sizeClass !== SizeClass.DESKTOP && <>
        <primitive ref={lightRef} object={light} position={[0, 5, -5]}/>
        <primitive object={light.target} position={[0, 0, 0]}/>
      </>}
      <ambientLight intensity={1}/>
    </>
  );
};

export default Lighting;