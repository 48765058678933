import React from 'react';
import {sizeClassStore} from 'stores';

import {ReactComponent as LogoSvg} from 'assets/images/skaleone_logo.svg';

import './Logo.scss';

const Logo: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);

  return (
    <div className={`product-sheet-logo ${sizeClass}`}>
      <LogoSvg className={'product-sheet-logo-svg'}/>
    </div>
  );
};

export default Logo;