import React, {useState} from 'react';
import DebugItem from 'components/Debug/DebugItem';
import {sizeClassStore} from 'stores';
import {renderStore, FSMStore, cameraStore} from 'webgl/stores';

import 'components/Debug/DebugOverlay.scss';
import {Stats} from '@react-three/drei';
import {useRafLoop} from 'react-use';
import {Vector3} from 'three';

/***
 * @deprecated TODO REMOVE DEBUG
 */
const DebugOverlay: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const isIdle = renderStore(state => state.isIdle);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const currentFSMStateSiblings = FSMStore(state => state.currentFSMStateSiblings);
  const activeVirtualCamera = cameraStore(state => state.activeVirtualCamera);
  const cameraBrain = cameraStore(state => state.cameraBrain);
  const [brainPosition, setBrainPosition] = useState<Vector3>(new Vector3());
  const [brainFov, setBrainFov] = useState<number>(-1);

  const takeScreenshot = () => {
    console.log('take screenshot');

    const a = document.createElement('a');
    a.href = document.getElementsByTagName('canvas')[0].toDataURL('image/png');
    a.setAttribute('download', 'webglCapture.png');
    if (window.confirm('Enregistrer une capture ?')) a.click();
  };

  useRafLoop(() => {
    if (cameraBrain) {
      setBrainPosition(cameraBrain.position);
      setBrainFov(cameraBrain.fov);
    }
  });

  return (
    <div className={`debug-overlay ${sizeClass}`}>
      <DebugItem text={'Current FSM State: '} value={currentFSMState} />
      <DebugItem text={'Current FSM State Siblings: '} value={currentFSMStateSiblings?.join(', ')} />
      <DebugItem text={'SizeClass: '} value={sizeClass} />
      <DebugItem text={'IsIdle: '} value={isIdle.toString()} />
      <DebugItem text={'Active Virtual Camera: '} value={`${activeVirtualCamera?.name} 
      [${activeVirtualCamera && activeVirtualCamera.position.toArray().map(comp => Math.round(comp * 100) /100).join(', ')}] 
      [fov: ${activeVirtualCamera && Math.round(activeVirtualCamera.fov * 100) / 100}]
      `} />
      <DebugItem text={'Camera Brain: '} value={`${cameraBrain?.name} 
      [${brainPosition.toArray().map(comp => Math.round(comp * 100) /100).join(', ')}] 
      [fov: ${Math.round(brainFov * 100) / 100}]
      `} />

      <button className="debug-item" style={{pointerEvents: 'auto'}} onPointerUp={takeScreenshot}>capture image</button>
      {/*// DEBUG FPS*/}
      <Stats
      showPanel={0} // Start-up panel (default=0)
      className="stats" // Optional className to add to the stats container dom element
    />;
    </div>
  );
};

export default DebugOverlay;