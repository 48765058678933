import create from 'zustand';
import {Object3DTypedArray} from 'webgl/types/Object3DTypedArray';
import {ProductSheet} from 'models/productSheet';
import {DynamicFSMStates, DynamicFSMStatesArray} from 'webgl/types/FSMStates';
import {StateToPlay} from 'webgl/hooks/useHandleChainedAnimations';

const flattenObjectToArray = (object: DynamicFSMStates, recursive: boolean = false): DynamicFSMStatesArray => {
  const statesArray: DynamicFSMStatesArray = [];

  Object.entries(object)
    .forEach(([key, value]) => {
      if (typeof value === 'string')
        statesArray.push({key: key, value: value});
      else if (recursive) statesArray.push(...flattenObjectToArray(value));
    });

  return statesArray;
};

export type ProductSheetStoreState = {
  productSheet?: ProductSheet.DTO;
  setProductSheet: (value: ProductSheet.DTO | undefined) => ProductSheet.DTO | undefined;

  productSheetStates: DynamicFSMStates; // { [p: string]: any }
  productSheetStatesArray: DynamicFSMStatesArray;
  productSheetSubStatesArray: DynamicFSMStatesArray;
  setProductSheetStates: (states: DynamicFSMStates) => void;
  /*used to determine which Annotation to display during chained action playing*/
  currentPlayingActionState?: StateToPlay,
  /*used to determine which Annotation to display during chained action playing*/
  setCurrentPlayingActionState: (state?: StateToPlay) => void,

  isProductSheet3dReady: boolean;
  setProductSheet3dReadiness: (value: boolean) => void;

  sortedObjects: Object3DTypedArray;
  setSortedObjects: (sortedObjects: Object3DTypedArray) => void;
};

const productSheetStore = create<ProductSheetStoreState>((set) => ({
  productSheet: undefined,
  setProductSheet: (product) => {
    set({
      productSheet: product,
      isProductSheet3dReady: false
    });
    return product;
  },

  productSheetStates: {},
  productSheetStatesArray: [],
  productSheetSubStatesArray: [],
  setProductSheetStates: (value: DynamicFSMStates) => {
    // const statesArray = Object.entries(value)
    //   .filter(([key, value]) => typeof value === 'string')
    //   .map(([key, value]) => ({key, value}));
    // set({productSheetStates: value, productSheetStatesArray: statesArray});
    set({productSheetStates: value, productSheetStatesArray: flattenObjectToArray(value), productSheetSubStatesArray: flattenObjectToArray(value, true)});
  },

  currentPlayingActionState: undefined,
  setCurrentPlayingActionState: (state) => set({currentPlayingActionState: state}),

  isProductSheet3dReady: false,
  setProductSheet3dReadiness: (value) => set({isProductSheet3dReady: value}),

  sortedObjects: {},
  setSortedObjects: (sortedObjects) => set({sortedObjects: sortedObjects})
}));

export {productSheetStore};
