import {logHelper, tLogStyled} from 'utils/Logger';

// TODO FAKE DATE

export type AnnotationOverlayData = {
  activeState: string,
  title?: string,
  iconUri?: string
  description?: string,
  closeState?: string,
  customData?: any
}

// TODO set Annotation states etc. here instead of glb userData ?
//      only set annotationId in GLB?

const annotationOverlayData__FAKE: AnnotationOverlayData[] = [
  {
    activeState: 'dynamicStates.details.detail1',
    title: 'Personnalisation sans limite',
    description: 'Intérieur BOIS, ALUMINIUM ou PVC. Coloris au choix int. et ext.',
    iconUri: './assets/img/icon_01.svg',
    closeState: 'dynamicStates.state3',
    customData: [
      {name: 'couleur 1', value: '#474d53'},
      {name: 'couleur 2', value: '#682014'},
      {name: 'couleur 3', value: '#91b692'},
      {name: 'couleur 4', value: '#ecddb6'},
    ]
  },
  {
    activeState: 'dynamicStates.details.detail2',
    title: 'Dormant de 72 mm et ouvrant de 78 mm',
    description: 'Constitué d\'une structure composite multi-chambres pour une isolation thermique haute performance',
    iconUri: './assets/img/icon_02.svg',
    closeState: 'dynamicStates.state3'
  },
  {
    activeState: 'dynamicStates.details.detail3',
    title: 'Vitrage',
    description: '4/20 Argon / 4FR en version M3D ALU et M3D BOIS Ug = 1,2 W / (m².K) Intercalaire alu noir',
    iconUri: './assets/img/icon_03.svg',
    closeState: 'dynamicStates.state3'
  },
];

// TODO SET ANNOTATION DATA TYPE
export const getAnnotationOverlayData_FAKE = async (state: string): Promise<AnnotationOverlayData | undefined> => {
  tLogStyled('[getAnnotationOverlayData_FAKE()] using fake annotation overlay data : annotationOverlayData__FAKE', logHelper.warning);

  return new Promise<AnnotationOverlayData | undefined>((resolve) => {
    setTimeout(() => resolve(annotationOverlayData__FAKE.find(data => data.activeState === state)), 0);
  });
};
