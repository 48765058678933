import create, {GetState, SetState} from 'zustand';
import {StoreApiWithSubscribeWithSelector, subscribeWithSelector} from 'zustand/middleware';
import {logHelper, tLogStyled} from 'utils/Logger';

export enum SizeClass {
  NULL = '',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile'
}

export type SizeState = {
  width: number;
  height: number;
  sizeClass: SizeClass;
  portrait: boolean;
  fovMultiplier: number;
  setSizeClass: (width: number, height: number) => void;
}

const sizeClassStore = create<SizeState, SetState<SizeState>, GetState<SizeState>, StoreApiWithSubscribeWithSelector<SizeState>>(subscribeWithSelector(set => ({
  width: 0,
  height: 0,
  sizeClass: SizeClass.NULL as SizeClass,
  portrait: false as boolean,
  fovMultiplier: 1,

  setSizeClass: (width, height): void => {
    // const portrait = window.innerHeight > window.innerWidth;
    const portrait = height > width;
    const sizeClass = getSizeClass();
    set({
      width: width,
      height: height,
      portrait: portrait,
      sizeClass: sizeClass,
      fovMultiplier: getFovMultiplier(portrait, sizeClass)
    });
  }
})));

const getFovMultiplier = (portrait: boolean, sizeClass: SizeClass): number => {
  // return 1;
  let fovMultiplier = portrait ? 1 : 1.25;

  // switch (sizeClass) {
  //   case SizeClass.TABLET :
  //     fovMultiplier = portrait ? 1.5 : 1;
  //     break;
  //   case SizeClass.MOBILE:
  //     fovMultiplier = portrait ? 2 : 1.5;
  //     break;
  //   default:
  //     fovMultiplier = portrait ? 1.25 : 1;
  // }
  tLogStyled(`[sizeClassStore] Using FOV multiplier of ${fovMultiplier}`, logHelper.processing); // DEBUG
  return fovMultiplier;
};

const getSizeClass = (): SizeClass => {
  const area = window.screen.width * window.screen.height;

  // desktop >= 1_500_000
  // tablet limit < 1_500_000
  // mobile limit < 500_000

  let sizeClass = SizeClass.DESKTOP;
  if (area >= 500_000 && area < 1_500_000) sizeClass = SizeClass.TABLET;
  if (area < 500_000) sizeClass = SizeClass.MOBILE;

  return sizeClass;
};

export const getReferenceResolution = (sizeClass: string, portrait: boolean) => {
  switch (sizeClass) {
    case SizeClass.MOBILE:
      return portrait ? {x: 400, y: 800} : {x: 800, y: 400};
    case SizeClass.TABLET:
      return portrait ? {x: 720, y: 1280} : {x: 1280, y: 720};
    default:
      return portrait ? {x: 1080, y: 1920} : {x: 1920, y: 1080};
  }
};

export {sizeClassStore};
