import {logHelper, tLogStyled} from 'utils/Logger';
import {ProductSheet} from 'models/productSheet';

// TODO FAKE DATA

const productSheets_FAKE: ProductSheet.DTO[] = [
  new ProductSheet.DTO('id_fenetre', 'key_fenetre', 'name_fenetre', 'fenetre',
    {
      default: 'default',   // display "Racontez votre produit" / webgl very far away
      state1: 'state1', // zoom in planet / zoom in webgl / display contact
      state2: 'state2', // display "en 3d"
      state3: 'state3', // display "sur le web" / display Annotations / satellite closed / display +-
      state4: 'state4', // satellite expanded solar panels
      state5: 'state5', // satellite exploded
      details: { // annotations zoom-ins (can't go to this state, only its substates)
        detail1: 'details.detail1',
        detail2: 'details.detail2',
        detail3: 'details.detail3',
        // detail4: 'details.detail4',
        // detail5: 'details.detail5',
        // detail6: 'details.detail6',
      },
      other: {
        moreInformation: 'other.moreInformation',
        contact: 'other.contact'
      }
    })
];

export const getProductSheets_FAKE = async (): Promise<ProductSheet.DTO[]> => {
  tLogStyled('[getProductSheets_FAKE()] using fake data : productSheets_FAKE', logHelper.warning);

  return new Promise<ProductSheet.DTO[]>((resolve) => {
    setTimeout(() => resolve(productSheets_FAKE), 0);
  });
};

export const getProductSheet_FAKE = async (productSheetId?: string): Promise<ProductSheet.DTO | undefined> => {
  tLogStyled('[getProductSheet_FAKE()] using fake data : productSheets_FAKE', logHelper.warning);

  return new Promise<ProductSheet.DTO | undefined>((resolve) => {
    setTimeout(() => resolve(productSheets_FAKE.find(ps => ps.id === productSheetId)), 0);
  });
};