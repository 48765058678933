import React from 'react';
import {getAssetList__FAKE} from 'services/getAssetList__FAKE';
import {updateAssetList} from 'webgl/services/webglService';
import WebGL from 'webgl/components/WebGL';
import ProductSheetViewer from 'components/Main/ProductSheet/ProductSheetViewer';

import './Main.scss';
import Header from "../Header/Header";

// PRELOAD ASSETS
getAssetList__FAKE() // TODO implement real method
  .then(updateAssetList); // will trigger glb preloading

const Main: React.FC = () => {


  return (
    <div className={`main flex flex-center-horizontal`}>
      <ProductSheetViewer productSheetId={'id_fenetre'}/> {/* TODO CHANGE KEY */}

      <Header/>
      {/*<MoreInformation/>*/}
      {/*<Contact/>*/}

      <WebGL/>
      {/*<AnnotationOrangeFixedDescriptions/>*/}
    </div>
  );
};

export default Main;
