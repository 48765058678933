import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {FSMStore} from 'webgl/stores';

import './ZoomInOut.scss';
import {ReactComponent as ZoomIn} from 'assets/images/zoom_in.svg';
import {ReactComponent as ZoomOut} from 'assets/images/zoom_out.svg';
import {useGetCurrentStateIndex} from 'webgl/hooks';
import {sizeClassStore} from 'stores';
import {FSMStates} from 'webgl/types/FSMStates';

enum ProductState {
  CLOSED,
  DEPLOYED,
  EXPLODED
}

const ZoomInOut: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const setFSMState = FSMStore(state => state.setFSMState);
  const {currentIndex, getStateFromIndex} = useGetCurrentStateIndex();

  const currentProductState = useMemo<ProductState>(() => {
    console.log("@@@ currentIndex:", currentIndex);
    if (currentIndex > 1 && currentIndex < 4) return ProductState.EXPLODED;
    else return ProductState.CLOSED;
  }, [currentIndex]);

  const zoomIn = useCallback(() => setFSMState(FSMStates.dynamicStates.state2), [currentIndex, getStateFromIndex, setFSMState]);
  const zoomOut = useCallback(() => setFSMState(FSMStates.dynamicStates.state4), [currentIndex, getStateFromIndex, setFSMState]);

  if (currentIndex < 2 || currentIndex > 4) return null; // hide if annotations are not visible (below state3)

  return (
    <div className={`zoom-in-out ${sizeClass}`}>

      <ZoomIn className={`zoom-in-out zoom-in${currentProductState === ProductState.EXPLODED ? ' zoom-in-out-inactive' : ' zoom-in-out-active'}`}
        onClick={zoomIn}/>

      <ZoomOut className={`zoom-in-out zoom-out${currentProductState === ProductState.CLOSED ? ' zoom-in-out-inactive' : ' zoom-in-out-active'}`}
        onClick={zoomOut}/>

    </div>
  );
};

export default ZoomInOut;
