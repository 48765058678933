import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {cameraStore, FSMStore, GLTFAssetStore} from 'webgl/stores';
import {AnnotationOverlayData, getAnnotationOverlayData_FAKE} from 'services/getAnnotationOverlayData_FAKE';

import './AnnotationOverlay.scss';
import {ReactComponent as BottomLine} from 'assets/images/annotation_ligne_bas.svg';
import {ReactComponent as CloseCross} from 'assets/images/annotation_croix_fermeture.svg';
import {sizeClassStore} from 'stores';
import {DefaultValues} from 'webgl/types/DefaultValues';
import {Color} from 'three';
// import {ReactComponent as ArrowPreviousInactive} from 'assets/images/annotation_fleche_inactive_gauche.svg';
// import {ReactComponent as ArrowNextInactive} from 'assets/images/annotation_fleche_inactive_droite.svg';

let timer: NodeJS.Timeout | undefined = undefined;

const AnnotationOverlay: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const overlayRef = useRef<HTMLDivElement>(null);

  const currentFSMState = FSMStore(state => state.currentFSMState);
  const currentFSMStateSiblings = FSMStore(state => state.currentFSMStateSiblings);
  const setFSMState = FSMStore(state => state.setFSMState);
  const setTargetCameraOffset = cameraStore(state => state.setTargetCameraOffset);
  const [overlayData, setOverlayData] = useState<AnnotationOverlayData>();
  const parsedAssets = GLTFAssetStore(state => state.parsedAssets);

  const currentStateSiblingIndex = useMemo(() => currentFSMStateSiblings.findIndex(state => state === currentFSMState), [currentFSMState, currentFSMStateSiblings]);
  const isFirst = useMemo(() => currentStateSiblingIndex === 0, [currentStateSiblingIndex]);
  const isLast = useMemo(() => currentStateSiblingIndex === currentFSMStateSiblings.length - 1, [currentFSMStateSiblings.length, currentStateSiblingIndex]);
  const previousState = useMemo(() => currentFSMStateSiblings[Math.max(0, currentStateSiblingIndex - 1)], [currentFSMStateSiblings, currentStateSiblingIndex]);
  const nextState = useMemo(() => currentFSMStateSiblings[Math.min(currentStateSiblingIndex + 1, currentFSMStateSiblings.length - 1)], [currentFSMStateSiblings, currentStateSiblingIndex]);

  const changeMaterialColor = useCallback((colorStr: string) => {
    parsedAssets.forEach(parsedAsset => parsedAsset.scene.traverse(obj => {
      // @ts-ignore
      const mat = obj.material;

      if (mat && mat.name === 'alu ext') { // TODO HARD CODED MATERIAL NAME
        mat.color = new Color(colorStr).convertSRGBToLinear();
      }
    }))
  }, [parsedAssets]);

  const blinkBg = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }

    timer = setTimeout(() => {
      setTimeout(() => {
        if (!overlayRef.current) return;

        overlayRef.current.style.backgroundColor = '#FFFFFF';
      }, 50);
      setTimeout(() => {
        if (!overlayRef.current) return;

        overlayRef.current.style.backgroundColor = 'black';
      }, 200);
    }, 0)
  }, []);

  useEffect(() => {
    getAnnotationOverlayData_FAKE(currentFSMState || '').then(setOverlayData).then(blinkBg);
  }, [blinkBg, currentFSMState]);

  useEffect(() => {
    if (overlayData)
      setTargetCameraOffset(DefaultValues.detailsCameraOffset, 0); // TODO change delay
    else
      setTargetCameraOffset(DefaultValues.cameraOffset, 600);

  }, [setTargetCameraOffset, overlayData]);

  return (
    <div ref={overlayRef} className={`annotation-overlay-container${!!overlayData ? ' opened' : ''} ${sizeClass}`}>
      <div className={'annotation-overlay'}>
        <CloseCross className={`annotation-overlay-close clickable  ${sizeClass}`}
                    onClick={() => setFSMState(overlayData?.closeState || 'dynamicStates.state2')}/>

        <div className={`annotation-overlay-title ${sizeClass}`}>{overlayData?.title}</div>

        {/*<div className={`annotation-overlay-icon ${sizeClass}`}>*/}
        {/*  <img src={overlayData?.iconUri} alt={overlayData?.title + ' icon'}/>*/}
        {/*</div>*/}

        <div className={`annotation-overlay-content ${sizeClass}`}>{overlayData?.description}</div>
        <div className={`annotation-overlay-colors ${sizeClass}`}>
          {overlayData?.customData?.map((data : {name: string, value: string}) => {
            return <div className={`annotation-overlay-color ${sizeClass}`} style={{backgroundColor: data.value}}
              onClick={() => changeMaterialColor(data.value)} key={Math.random()*10000}/>
          })}
        </div>

        <div className={`annotation-overlay-previous${isFirst ? '' : ' active'} ${sizeClass}`}
             onClick={() => setFSMState(previousState)}/>
        <div className={`annotation-overlay-next${isLast ? '' : ' active'} ${sizeClass}`}
             onClick={() => setFSMState(nextState)}/>
      </div>
    </div>
  );
};

export default AnnotationOverlay;
