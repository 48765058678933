import {useEffect, useState} from 'react';
import {cameraStore, GLTFAssetStore, productSheetStore} from 'webgl/stores';
import {logHelper, tLogStyled} from 'utils/Logger';
import {Asset3D} from 'webgl/interfaces';
import {AssetEntity} from 'webgl/entities/AssetEntity';

const getAssetEntity = (productKey: string, assets: Asset3D[]): AssetEntity | undefined => {
  const asset = assets.find(asset => asset.key === productKey);
  if (asset) {
    return new AssetEntity(asset);
  }
};

export const useGetProductAssetEntity = (productKey?: string): AssetEntity | undefined => {
  const parsedAssets = GLTFAssetStore(state => state.parsedAssets);
  const setProductSheet3dReadiness = productSheetStore(state => state.setProductSheet3dReadiness);
  const resetVirtualCameras = cameraStore(state => state.resetVirtualCameras);

  const [productAssetEntity, setProductAssetEntity] = useState<AssetEntity>();

  useEffect(() => {
    setProductSheet3dReadiness(false); // moved to ProductSheetViewer?
    resetVirtualCameras();
    tLogStyled('[useGetProductAssetEntity] resetVirtualCameras()', logHelper.debug);

    if (productKey) {
      if (parsedAssets.length === 0) {
        tLogStyled(`[useGetProductAssetEntity] New productKey received but no loaded asset available`, logHelper.warning, productKey, parsedAssets); // TODO REMOVE DEBUG

      } else { // parsedAssets length > 0

        // Equipments
        const _newProductAsset3D = getAssetEntity(productKey, parsedAssets);
        setProductAssetEntity(_newProductAsset3D);

        // setProductSheet3dReadiness(true); // moved to useUpdateLineVirtualCameras.ts
      }
    }
  }, [productKey, parsedAssets, resetVirtualCameras, setProductSheet3dReadiness]);

  // useUpdateLineStates(equipmentEntities);
  // useUpdateLineSortedObjects(equipmentEntities);
  // useUpdateLineVirtualCameras(equipmentEntities);

  return productAssetEntity;
};