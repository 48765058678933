import {FSMStore, productSheetStore} from 'webgl/stores';
import {useCallback, useMemo} from 'react';

export const useGetCurrentStateIndex = () => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const currentPlayingActionState = productSheetStore(state => state.currentPlayingActionState);
  const productSheetSubStatesArray = productSheetStore(state => state.productSheetSubStatesArray);
  const currentIndex = useMemo(() =>
      productSheetSubStatesArray.findIndex(state => currentPlayingActionState?.state ?
        state.value === currentPlayingActionState?.state :
        state.value === currentFSMState),
    [currentFSMState, currentPlayingActionState?.state, productSheetSubStatesArray]);
  const getStateFromIndex = useCallback((index: number): string => productSheetSubStatesArray[index].value, [productSheetSubStatesArray])

  return {currentIndex, currentFSMState, currentPlayingActionState, getStateFromIndex};
};