import {DynamicFSMStates} from 'webgl/types/FSMStates';

export namespace ProductSheet {
  export class DTO {
    id: string;
    key: string;
    name: string;

    productKey: string;

    states: DynamicFSMStates;

    constructor(id: string, key: string, name: string, productKey: string, states?: DynamicFSMStates) {
      this.id = id;
      this.key = key;
      this.name = name;
      this.productKey = productKey;
      this.states = states || {};
    }
  }
}
