import React from 'react';
import {GLTFAssetStore, loadingStore, productSheetStore} from 'webgl/stores';
import {useGetProductAssetEntity} from 'webgl/hooks/productSheet/useGetProductAssetEntity';
import {
  useCameraController,
  useCameraOffset,
  useUpdateProductSheetSortedObjects,
  useUpdateProductSheetVirtualCameras
} from 'webgl/hooks';
import Annotations from 'webgl/components/Annotations/Annotations';
import {useUpdateProductSheetStates} from 'webgl/hooks/productSheet/useUpdateProductSheetStates';
import Product from 'webgl/components/ProductSheet/Product';
import {ContactShadows} from '@react-three/drei';

const ProductSheet3D: React.FC = () => {
  const {isLoading} = loadingStore(state => ({isLoading: state.isLoading}));
  const {isParsing} = GLTFAssetStore(state => ({isParsing: state.isParsing}));
  const {productSheet, isProductSheet3dReady} = productSheetStore(state => ({
    productSheet: state.productSheet,
    isProductSheet3dReady: state.isProductSheet3dReady
  }));

  const productEntity = useGetProductAssetEntity(productSheet?.productKey);

  useUpdateProductSheetStates(productSheet);
  useUpdateProductSheetSortedObjects(productEntity);
  useUpdateProductSheetVirtualCameras(productEntity);

  useCameraOffset();
  useCameraController(); // TODO do not use cameraController? rotate product instead?

  if (isLoading || isParsing) return null;
  if (!productSheet || !isProductSheet3dReady) return null;
  if (!productEntity) return null;

  return (
    <group name={'ProductSheet3D Group'}>
      <Product productEntity={productEntity}/>
      <ContactShadows rotation-x={Math.PI / 2} position={[0, -.03, 0]} opacity={.2} width={3} height={4} far={5} blur={.5} smooth={false}/>
      <Annotations/>
    </group>
  );
};

export default ProductSheet3D;