import React from 'react';

import './Header.scss';
import Logo from "../Main/ProductSheet/Logo";
import Contact from "../Main/ProductSheet/Contact";


const Header: React.FC = () => {
  return <div className={'header'}>
    <Logo/>
    <Contact/>
  </div>;
}

export default Header;
